import React, { useCallback, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import Turnstile from "react-turnstile";
import CustomButton from "../elements/CustomButton";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const ContactForm = ({ className }) => {
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState();

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);

    fetch("/api/forms/submit", {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((res) => res.json())
      .then((res) => {
        const { success } = res;

        if (success) {
          e.target.reset();
          setSubmitStatus({
            message: "Thank you for your message.",
            type: "success",
          });
        } else {
          setSubmitStatus({
            message: "Something went wrong. Please try again later.",
            type: "danger",
          });
        }
      })
      .catch(() => {
        setSubmitStatus({
          message: "Something went wrong.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Form className={className} onSubmit={onSubmit}>
        {/*
          Optional:
          Add a hidden input with name="Form" to send a form specific name,
          this will be visible only to the email recipient.
        */}
        <input type="hidden" name="Form" value="Contact" />

        <Form.Group className="mb-3" controlId="contact.name">
          {/* <Form.Label>Name</Form.Label> */}
          <Form.Control
            type="text"
            name="Name"
            placeholder="Ονοματεπώνυμο"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="contact.email">
          {/* <Form.Label>Email*</Form.Label> */}
          <Form.Control
            type="text"
            name="phone"
            placeholder="Τηλέφωνο επικοινωνίας"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="contact.email">
          {/* <Form.Label>Email*</Form.Label> */}
          <Form.Control
            type="email"
            name="Email"
            placeholder="Email"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="contact.message">
          {/* <Form.Label>Message</Form.Label> */}
          <Form.Control
            type="text"
            name="job_possition"
            placeholder="Θέση εργασίας"
          />
        </Form.Group>

        {/* <Form.Group className="custom-file-input" controlId="contact.file">
          <Form.Label className="mb-sm-0 text-nowrap">File</Form.Label>
          <Form.Control className="custom-file-input" type="file" name="File" />
        </Form.Group> */}
        <div className="inputFileContainer">
          <StaticImage
            src="../images/icons/insertCV.svg"
            className="insertImage"
            alt="insert cv"
          />
          <input type="file" name="file" className="custom-file-input" />
        </div>

        <Turnstile
          sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
          theme="light"
          onError={() => setDisable(true)}
          onExpire={() => setDisable(true)}
          onVerify={() => setDisable(false)}
          className="mb-3"
        />
        <Button type="submit" disabled={disable || loading}>
          SEND
          {loading && <Spinner animation="border" size="sm" className="ms-2" />}
        </Button>
      </Form>
      {submitStatus && (
        <Alert
          variant={submitStatus.type}
          className="my-3 text-center"
          onClose={() => setSubmitStatus()}
          dismissible
        >
          {submitStatus.message}
        </Alert>
      )}
    </>
  );
};

export default ContactForm;
