/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicRichText } from "@prismicio/react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import axios from "axios";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import { constructActiveDoc } from "../utilities";
import CustomButton from "../components/elements/CustomButton";
import ContactForm from "../components/common/ContactForm";

const NascoJobs = ({ data }) => {
  if (!data) return null;
  const { prismicSettings: settings, prismicNascoJobs: jobs } = data;

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/6d8a93c6-5607-47b6-b507-6834099733c6",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  const JobsForm = () => {
    if (serverState.submitting) {
      return <h3>Αποστολή...</h3>;
    }
    if (serverState.status?.ok) {
      return <h3>Θα επικοινωνήσουμε μαζί σας το συντομότερο.</h3>;
    }
    if (serverState.status?.ok === false) {
      return <h3>Παρακαλώ προσπαθήστε ξανά.</h3>;
    }
    return (
      <form
        onSubmit={handleOnSubmit}
        encType="multipart/form-data"
        className="d-flex flex-column"
      >
        <input
          type="text"
          className="form-control mb-3 border-0"
          name="name"
          placeholder="Ονοματεπώνυμο"
          required
        />
        <input
          type="text"
          className="form-control mb-3 border-0"
          name="phone"
          placeholder="Τηλέφωνο επικοινωνίας"
        />
        <input
          type="email"
          className="form-control mb-3 border-0"
          name="email"
          placeholder="Email"
        />
        <input
          type="text"
          className="form-control mb-3 border-0"
          name="job_possition"
          placeholder="Θέση εργασίας"
        />
        <div className="inputFileContainer">
          <StaticImage
            src="../images/icons/insertCV.svg"
            className="insertImage"
            alt="insert cv"
          />
          <input type="file" name="file" className="custom-file-input" />
        </div>
        <CustomButton label="ΑΠΟΣΤΟΛΗ" type="submit" />
      </form>
    );
  };

  return (
    <>
      <Seo page={jobs} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(jobs)}>
        <Container className="jobs-container">
          <GatsbyImage
            image={jobs.data.main_image.gatsbyImageData}
            alt={jobs.data.main_image.alt || "jobs image"}
            className="rounded"
          />
          <div className=" my-5 p-4 bg-light rich-text-slice-container">
            <Row>
              <h1 className="my-4">{jobs.data.title}</h1>
            </Row>
            <Row>
              <Col xl={7} className="pe-xl-5">
                <PrismicRichText field={jobs.data.open_positions.richText} />
              </Col>
              <Col xl={5}>
                <strong className="pb-4">{jobs.data.form_text}</strong>
                {/* <JobsForm /> */}
                <ContactForm className="d-flex flex-column" />
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query nascoJobs($lang: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    prismicNascoJobs(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        main_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        form_text
        title
        open_positions {
          richText
        }
      }
    }
  }
`;
export default withPrismicPreview(NascoJobs);
